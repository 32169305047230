<template>
  <div class="autorized-list">
    <div v-loading="is_pending" class="autorized-list-box">
        <div class="autorized-list-box-header">
            <h3> שם אירוע: {{event.title}}</h3>
            <h3>רשימת משתתפים</h3>
            <div @click="$emit('close')" class="exit-btn">
                <i class="material-icons">close</i>
            </div>
        </div>
        <div class="autorized-list-box-content">
            <div @click="handle_mark_all" :data-all-marked="all_branched_marked" class="tag everyone">הכל</div>
            <template v-for="branche in clone_event.authorized" :key="branche.branche_num">
              <div @click="handle_mark_branche(branche)" :data-tag="branche.marked" class="tag">{{branche.name}}</div>
            </template>
        </div>
        <div class="autorized-list-box-footer">
            <button @click="handle_submit" class="update-btn">עדכון</button>
        </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import {slide_pop_error} from '../../../../../../Methods/Msgs'
export default {
    emits:['close','update_authorized'],
    props:['event'],
    setup(props,{emit}){
        const is_pending = ref(false)
        const clone_event = ref(JSON.parse(JSON.stringify(props.event)))

        const all_branched_marked = ref(computed(() => {
          return clone_event.value.authorized.every(({marked}) => marked)
        }))

        const handle_mark_all = () => {
          if(all_branched_marked.value){
            clone_event.value.authorized.forEach(b => b.marked = false)
          }else{
            clone_event.value.authorized.forEach(b => b.marked = true)
          }
        }

        const handle_mark_branche = (branche) => {
          branche.marked = !branche.marked
        }

        const handle_submit = () => {
            is_pending.value = true
            try{
                is_pending.value = true
                if(!clone_event.value.authorized.some(({marked}) => marked)) throw new Error('עליך לסמן מי יורשה לצפייה!')
                emit('update_authorized',clone_event.value)
                is_pending.value = false
            }catch(err){
                is_pending.value = false
                slide_pop_error(err.message)
            }
        }

        



        return{
            clone_event,
            all_branched_marked,
            handle_mark_all,
            handle_mark_branche,
            handle_submit,
            is_pending,
        }
    }
}
</script>

<style scoped>
    .autorized-list{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        padding: 0 5px;
    }
    .autorized-list-box{
        width: 100%;
        max-width: 500px;
        min-height: 200px;
        background: #fff;
        border-radius: 10px;
    }

    .autorized-list-box-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid lightgray;
    }
    .autorized-list-box-content{
        width: 100%;
        min-height: calc(100% - 100px);
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        padding: 5px;
    }
    .autorized-list-box-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: var(--blue);
        border-radius: 50%;
        cursor: pointer;
        user-select: none;
     }
    .tag{
        padding: 2px 5px;
        background: lightgray;
        display: flex;
        align-items: center;
        border-radius: 10px;
        color: #333;
        user-select: none;
        cursor: pointer;
    }
    .tag.everyone{
        padding: 4px 10px;
        font-weight: 400;
    }
    [data-tag=true]{
        background: var(--blue);
        color: #fff;
    }
    [data-all-marked=true]{
        background: var(--success);
        color: #fff;
    }
    .update-btn{
        width: 80%;
        height: 40px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: var(--blue);
        border: 0;
        border-radius: 10px;
        cursor: pointer;
        user-select: none;
    }
</style>